import React from 'react'
import { useIntl, Link } from 'gatsby-plugin-intl'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import SEO from '../../components/seo'
import PressRelease from '../../components/PressRelease'
import ITBBookingAppointment from '../../components/PressRelease/ITBBookingAppointment'

import messages from '../../messages/pageTitles.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import Image from '../../images/news/runa_1_1_en.png'
import ImageDE from '../../images/news/runa_1_1_de.png'

import { newsList } from '../../components/NewsPage/data'

/* eslint-disable max-len */
const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleNewsRunaLaunch)}
        imageAlt={formatMessage(messages.TitleNewsRunaLaunch)}
        description={formatMessage(metaDescription.NewsRunaLaunchDescription)}
        image={locale === 'de' ? ImageDE : Image}
        lang={locale}
      />
      <PressRelease
        article={newsList.runa}
        articleLinks={{
          tsbo: (
            <Link to="/products/quickstart/">
              TravelSandbox®
            </Link>
          ),
          fvw: (
            <a href='https://www.fvw.de/touristik/vertrieb/reisen-mit-rollstuhl-runa-reisen-praesentiert-neue-website-248746' target="_blank" rel="noopener noreferrer">https://www.fvw.de/touristik/vertrieb/reisen-mit-rollstuhl-runa-reisen-praesentiert-neue-website-248746</a>
          ),
          trvl: (
            <a href='https://trvlcounter.de/counter-vertrieb/16509-runa-reisen-ueberarbeitet-buchungsportal/' target="_blank" rel="noopener noreferrer">https://trvlcounter.de/counter-vertrieb/16509-runa-reisen-ueberarbeitet-buchungsportal/</a>
          ),
          about: (
            <a href='https://abouttravel.ch/reisebranche/auch-rollstuhlfahrerinnen-koennen-online-buchen/' target="_blank" rel="noopener noreferrer">https://abouttravel.ch/reisebranche/auch-rollstuhlfahrerinnen-koennen-online-buchen/</a>
          ),
          die: (
            <a href='https://die-nachrichten.at/reisen/neue-website-fuer-barrierefreies-reisen-runa-reisen-erleichtert-buchungen/#google_vignette' target="_blank" rel="noopener noreferrer">https://die-nachrichten.at/reisen/neue-website-fuer-barrierefreies-reisen-runa-reisen-erleichtert-buchungen/#google_vignette</a>
          )
        }}
        addon={<ITBBookingAppointment />}
      />
    </Layout>
  )
}

export default IndexPage
